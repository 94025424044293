import { useQuery } from '@apollo/client';
import useIsBrowser from 'hooks/useIsBrowser';

import { GET_PROJECT_SIMON_CONFIG } from 'mocks/queries';

const DEFAULT_CONFIG = {
  isProjectSimonEnabled: true,
  projectSimonAttributeLabel: 'PANTONE®',
  projectSimonCylindoNames: {
    'Royale in Pantone 17-1230 Mocha Mousse': 'Royale Blush 2',
  },
  projectSimonFabricName: 'Royale in Pantone 17-1230 Mocha Mousse',
  projectSimonFabricSkus: ['-CF538'],
  projectSimonFilterFlyout: {
    imageUrl: 'https://joybird2.imgix.net/public_img/Simon-filter-hover.jpeg',
    content:
      'PANTONE 17-1230 Mocha Mousse is an evocative soft brown that transports our senses into the pleasure and deliciousness it inspires as the Pantone Color of the Year selection for 2025. A warming rich brown hue, PANTONE 17-1230 Mocha Mousse nurtures with its suggestion of the delectable quality of cacao, chocolate and coffee, appealing to our desire for comfort.',
    title: 'PANTONE Color of Year 2025',
  },
  projectSimonLinkInNavbar: {
    desktopIdToReplace: 'fabric-of-the-month',
    id: 'pantone',
    image: 'https://joybird2.imgix.net/public_img/Simon-Nav-Menu-Image.jpg',
    label: 'Joybird x Pantone',
    url: '/collaborations/pantone/',
  },
  projectSimonPage: {
    customListSlug: 'pantone-dec-2024',
    pageTitle: 'Joybird x Pantone',
    syndecaCatalogKey: 'joybird-x-pantone',
    syndecaGuideKey: '82ADE6DA-AF98-1341-AC8B-66A9BBAAC9E7',
  },
};

const useProjectSimonConfig = () => {
  const isBrowser = useIsBrowser();

  const { data, loading } = useQuery(GET_PROJECT_SIMON_CONFIG, {
    ssr: false,
  });

  const isLoading = loading && !data;

  const dataWithFallback =
    !isBrowser || isLoading
      ? DEFAULT_CONFIG
      : data?.getProjectSimonConfig || DEFAULT_CONFIG;

  return {
    isBrowser,
    isLoading,
    data: dataWithFallback,
  };
};

export default useProjectSimonConfig;
