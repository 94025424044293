import { useMemo } from 'react';

import { convertProductOptionsToUsableFormat } from 'components/consumer/PDP/helpers/pdpOptionUtils';
import useAllOptions from 'data-hooks/useAllOptions';
import { UPHOLSTERY_OPTION_IDS } from 'commons/constants';
import useProjectSimonConfig from 'data-hooks/useProjectSimonConfig';
import usePDPQueryOptionsForProduct from './usePDPQueryOptionsForProduct';

const getUpholsteryOption = (options = []) =>
  options?.find(({ id }) => UPHOLSTERY_OPTION_IDS.includes(id));

const usePDPGetOptionsForProduct = (
  { id, slug } = {},
  { skip = false } = {}
) => {
  // Get all options (for all products)
  const {
    loading: allOptionValuesLoading,
    allOptionsValues,
    error: allOptionValuesError,
  } = useAllOptions();

  const isAllOptionsLoading = allOptionValuesLoading && !allOptionsValues;

  // Get all options for this specific product
  const {
    loading: productOptionsLoading,
    error: productOptionsError,
    data: productOptionsData,
  } = usePDPQueryOptionsForProduct({ id, slug }, { skip });

  const { data: projectSimonConfig } = useProjectSimonConfig();
  const { isProjectSimonEnabled, projectSimonFabricSkus } = projectSimonConfig;

  const productOptions = useMemo(() => {
    if (!productOptionsData?.productOptions || !allOptionsValues) {
      return null;
    }

    const finalOptions = convertProductOptionsToUsableFormat(
      productOptionsData,
      allOptionsValues
    );

    const upholsteryOption = getUpholsteryOption(finalOptions);

    if (upholsteryOption?.values?.length) {
      upholsteryOption.values = upholsteryOption.values.filter(
        material =>
          isProjectSimonEnabled ||
          !projectSimonFabricSkus.includes(material.sku)
      );
    }

    return finalOptions;
  }, [
    productOptionsData,
    allOptionsValues,
    isProjectSimonEnabled,
    projectSimonFabricSkus,
  ]);

  return {
    data: productOptions,
    loading: productOptionsLoading || isAllOptionsLoading,
    error: productOptionsError || allOptionValuesError,
  };
};

export default usePDPGetOptionsForProduct;
